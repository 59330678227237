import React, { useState, useEffect } from 'react';
import SectionWrapper from "../UI/SectionWrapper";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import WoodIcon from '../../assets/icon/wood-icon.svg';
import MetalIcon from '../../assets/icon/metal-icon.svg';
import BackgroundImageTwo from '../../assets/img/project-back-test-two.png';
import ProjectCard from "../UI/ProjectCard";
import OfficeImg from '../../assets/img/office.png';
import Ogorodzhenia from '../../assets/img/ogorodzhenia.png';
import Sadmebli from '../../assets/img/sadmebli.png';
import Shody from '../../assets/img/shody.png';
import MebliLoft from '../../assets/img/loft.png';
import Navisy from '../../assets/img/navisy.png';

const useStyles = makeStyles((theme) => ({
    title: {
        fontSize: '48px',
        fontWeight: 'bold',
        color: '#F2F2F2',
        fontFamily: 'Inter-Bold',
        textAlign: 'center',
        marginBottom: '32px',
        [theme.breakpoints.down('sm')]: {
            fontSize: '20px',
        }
    },
    animation: '$fadeOut 0.7s ease',
    '@-webkit-keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@-moz-keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@-o-keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
    '@keyframes fadeOut': {
        '0%': {
            opacity: 1,
        },
        '100%': {
            opacity: 0,
        },
    },
}));

const bestProjectsData = [
    {
        id: 1,
        title: 'Навіси',
        description: 'Навіси та козирки використовуються для захисту входу в будівлю, а також як окрема конструкція, наприклад, навіс для машини. Навіс до будинку над терасою є чудовим місцем для відпочинку. Навіси виготовляють із металу та нержавіючої сталі. Покриття може бути з полікарбонату, профнастилу, металочерепиці.\n',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            },
        ],
        bannerImage: Navisy,
    },
    {
        id: 2,
        title: 'Меблі лофт',
        description: 'Меблі лофт необхідна деталь популярного стилю лофт. Головна відмінність меблів лофт – це використання металу і дерева, прості форми, з грубою обробкою, але зі своєю оригінальністю та шармом. Меблі лофт припускає натуральні матеріали, часто зістарені, з неоднорідною структурою.',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            }
        ],
        bannerImage: MebliLoft,
    },
    {
        id: 3,
        title: 'Огородження',
        description: 'Огородження для сходів, балконів, терас, територій. Огородження забезпечують безпеку, є окрасою будинків та вулиць. Виготовляються з різних матеріалів та їх комбінацій: метал, нержавіюча сталь, кування, скло, дерево і т.д. Можемо запропонувати готові рішення огорож або спроектувати відповідно до Вашого завдання.\n',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            }
        ],
        bannerImage: Ogorodzhenia,
    },
    {
        id: 4,
        title: 'Сходи',
        description: 'Сходи ми виготовляємо на металокаркасі. Безумовно, сходи можуть бути повністю з дерева або бетону, але саме метал дозволяє робити сходи з будь-якою дизайнерською фантазією та відповідною до будь-якого стилю. Сходи вимагають серйозного дизайнерського та проектного опрацювання.',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            }
        ],
        bannerImage: Shody,
    },
    {
        id: 5,
        title: 'Меблі для саду',
        description: 'Садові меблі все більш затребувані власниками будинків та заміських ділянок. Люди втомлюються від суєти великого міста та шукають усамітнення з природою. Меблі для саду з металу мають цілу низку переваг: міцність, довговічність, високі антикорозійні властивості.',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            }
        ],
        bannerImage: Sadmebli,
    },
    {
        id: 6,
        title: 'А-фрейм',
        description: 'Трикутні будинки можна зустріти не так вже й часто у нашій країні, але це є\n' +
            'дуже гідною альтернативою звичних для всіх будинків прямокутної форми.\n' +
            'Через незначну площу для зведення трикутного будинку не буде потрібно\n' +
            'великої земельної ділянки. При цьому будинок є досить міцним, надійним та\n' +
            'йому не страшні ніякі погодні умови. Сніг так взагалі практично не\n' +
            'затримується на даху завдяки кутку його нахилу.',
        icons: [
            {
                src: WoodIcon,
                alt: 'project',
            },
            {
                src: MetalIcon,
                alt: 'project',
            }
        ],
        bannerImage: OfficeImg,
    },
];

const Projects = () => {
    const classes = useStyles();
    const [currentProjectIndex, setCurrentProjectIndex] = useState(0);

    useEffect(() => {
        const timer = setTimeout(() => {
            setCurrentProjectIndex((prevIndex) => (prevIndex + 1) % bestProjectsData.length);
        }, 10000);

        return () => clearTimeout(timer);
    }, [currentProjectIndex]);

    return (
        <SectionWrapper id="projects">
            <Box>
                <Typography className={classes.title}>Наші проєкти</Typography>
            </Box>
            {[...Array(2)].map((_, index) => {
                const projectIndex = (currentProjectIndex + index) % bestProjectsData.length;
                const isAnimatedOut = index === 0 && projectIndex !== currentProjectIndex;
                const projectCardClass = isAnimatedOut ? classes.animateOut : '';

                return (
                    <ProjectCard
                        key={index}
                        project={bestProjectsData[projectIndex]}
                        className={projectCardClass}
                    />
                );
            })}
        </SectionWrapper>
    );
};

export default Projects;




