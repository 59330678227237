import React, { useState } from 'react';
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Box, Typography, useMediaQuery } from "@material-ui/core";
import CustomButton from "../Button";
import ArrowIcon from '../../../assets/icon/arrow-icon.svg';

const useStyles = makeStyles((theme) => ({
    infoCard: {
        flex: '0 0 30%',
        borderRadius: '10px',
        backgroundColor: '#f2f2f2',
        padding: '40px 20px',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '20px',
        // justifyContent: 'space-between',
        transition: 'all 0.5s ease',
        '-webkit-transition': 'all 0.5s ease', // Добавлен вендорный префикс для Webkit (Chrome, Safari)
        '-moz-transition': 'all 0.5s ease', // Добавлен вендорный префикс для Moz (Firefox)
        '-o-transition': 'all 0.5s ease', // Добавлен вендорный префикс для O (Opera)
        transformOrigin: 'top',
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            gap: '10px',
        },
    },
    boxView: {
        display: 'flex',
        alignItems: 'stretch',
        justifyContent: 'center',
        gap: '20px',
        height: '500px',
        marginBottom: '20px',
        transition: 'all 0.5s ease',
        '-webkit-transition': 'all 0.5s ease',
        '-moz-transition': 'all 0.5s ease',
        '-o-transition': 'all 0.5s ease',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 'auto',
            marginBottom: '32px',
        },
    },
    imgCard: {
        borderRadius: '10px',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'flex-end',
        paddingBottom: '20px',
        transition: 'all 0.5s ease',
        '-webkit-transition': 'all 0.5s ease',
        '-moz-transition': 'all 0.5s ease',
        '-o-transition': 'all 0.5s ease',
        [theme.breakpoints.down('sm')]: {
            height: '200px',
        },
    },
    subtitle: {
        fontSize: '22px',
        fontWeight: 'bold',
        color: '#161616',
        fontFamily: 'Inter-Bold',
        lineHeight: '26px',
        animation: `$fade-in 0.5s ease`,
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        },
    },
    icon: {
        borderRadius: '10px',
        animation: `$fade-in 0.5s ease`,
        border: '1px solid #525252',
        padding: '10px',
    },
    description: {
        fontSize: '20px',
        color: '#161616',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        animation: `$fade-in 0.5s ease`,
        display: '-webkit-box',
        //'-webkit-line-clamp': 12, // Ограничение на 6 строк
        '-webkit-box-orient': 'vertical',
        overflow: 'hidden', // Скрываем остаток текста
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        },
    },
    arrowIcon: {
        width: '24px',
        height: '24px',
        transform: 'rotate(0deg)',
        transition: 'transform 0.5s ease',
    },
    expanded: {
        transform: 'rotate(-90deg)',
        transition: 'transform 0.5s ease',
    },
    '@-webkit-keyframes fade-in': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@-moz-keyframes fade-in': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@-o-keyframes fade-in': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@keyframes fade-in': {
        '0%': {
            opacity: 0,
        },
        '100%': {
            opacity: 1,
        },
    },
    '@-webkit-keyframes expand-card': {
        '0%': {
            height: '80%',
        },
        '100%': {
            height: '100%',
        },
    },
    '@-moz-keyframes expand-card': {
        '0%': {
            height: '80%',
        },
        '100%': {
            height: '100%',
        },
    },
    '@-o-keyframes expand-card': {
        '0%': {
            height: '80%',
        },
        '100%': {
            height: '100%',
        },
    },
    '@keyframes expand-card': {
        '0%': {
            height: '80%',
        },
        '100%': {
            height: '100%',
        },
    },
}));

const ProjectCard = ({ project }) => {
    const classes = useStyles();
    const [expanded, setExpanded] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    const handleArrowClick = () => {
        setExpanded(!expanded);
    };

    return (
        <Box className={`${classes.boxView} ${expanded ? 'expanded' : ''}`}>
            <Box className={`${classes.infoCard} ${expanded ? 'expanded' : ''}`}>
                <Box style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Typography className={classes.subtitle}>{project.title}</Typography>
                    {isMobile && (
                        <img
                            src={ArrowIcon}
                            alt="Expand"
                            className={`${classes.arrowIcon} ${expanded ? classes.expanded : ''}`}
                            onClick={handleArrowClick}
                        />
                    )}
                </Box>
                {(expanded || !isMobile) && (
                    <>
                        <Typography className={classes.description}>{project.description}</Typography>
                        <Typography className={classes.subtitle}>Що використовували</Typography>
                        <Box>
                            <Box style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
                                {project.icons.map((icon, index) => (
                                    <img key={index} src={icon.src} alt={icon.alt} className={classes.icon} />
                                ))}
                            </Box>
                        </Box>
                    </>
                )}
            </Box>
            <Box className={classes.imgCard} style={{ backgroundImage: `url(${project.bannerImage})` }}>
                <CustomButton text="Зателефонувати" width="80%" mobileWidth="50%" />
            </Box>
        </Box>
    );
};

export default ProjectCard;

