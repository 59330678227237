import React from 'react';
import {Box} from '@material-ui/core';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import ContactButton from "../../UI/ContactButton";
import CustomButton from "../../UI/Button";


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: '#1E1E1E',
        padding: '40px 40px',
        //marginTop: '75px',

        [theme.breakpoints.down('sm')]: {
            padding: '60px 20px 20px 20px',
        },
        [theme.breakpoints.up('lg')]: {
            padding: '100px'
        },
        [theme.breakpoints.up('xl')]: {
            padding: '150px'
        }
    },
    title: {
        color: '#F2F2F2',
        fontSize: '32px',
        fontFamily: 'Inter-Bold',
        lineHeight: '38px',
        width: '30%',
        marginBottom: '33px',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            textAlign: 'center',
            fontSize: '20px',
        }
    },
    contactCard: {
        height: '100%',
        backgroundColor: '#fff',
        borderRadius: '20px',
        padding: '20px',
    },
    boxView: {
        display: 'grid',
        gridTemplateColumns: '1fr 2fr',
        columnGap: '10%',
        alignItems: 'start',
        [theme.breakpoints.down('sm')]: {
            gridTemplateColumns: '1fr',
            rowGap: '20px',
        }
    },
    buttonContainer: {
        display: 'grid',
        gridTemplateColumns: '1fr 1fr',
        rowGap: '20px',
        columnGap: '20px',
        justifyItems: 'end',
        [theme.breakpoints.down('sm')]: {
            rowGap: '10px',
            columnGap: '10px',
        }
    },
    titleCard: {
        fontSize: '28px',
        fontFamily: 'Inter-Bold',
        lineHeight: '24px',
        color: '#262626',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    subTitleCard: {
        fontSize: '18px',
        fontFamily: 'Inter-Regular',
        lineHeight: '21px',
        color: '#262626',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    },
    textContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '12px',
        marginBottom: '40px',
    },
    contactText: {
        fontSize: '20px',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: '#000',
        fontWeight: '500',
        [theme.breakpoints.down('sm')]: {
            fontSize: '16px',
        }
    },
    description: {
        fontSize: '18px',
        fontFamily: 'Inter-Regular',
        lineHeight: '24px',
        color: '#000',
        [theme.breakpoints.down('sm')]: {
            fontSize: '14px',
        }
    }
}));

const Footer = () => {
        const classes = useStyles();

        return (
            <Box className={classes.root} id="contact">
                <Box>
                    <Typography className={classes.title}>
                        МИ ЗАВЖДИ НА ЗВ'ЯЗКУ
                    </Typography>
                </Box>
                <Box className={classes.boxView}>
                    <Box className={classes.contactCard}>
                        <Box className={classes.textContainer}>
                            <Typography className={classes.titleCard}>
                                Зв'яжіться з нами
                            </Typography>
                            <Typography className={classes.description}>
                                Наберіть нам або зв’яжіться по пошті!
                            </Typography>
                            <Typography className={classes.contactText}>
                                <a href="tel:+380931515155" style={{
                                    textDecoration: 'none', color: 'inherit'
                                }}>
                                    +380 93 151 51 55
                                </a>
                            </Typography>
                            <Typography className={classes.contactText}>
                                <a href="tel:+380970040005" style={{
                                    textDecoration: 'none', color: 'inherit'
                                }}>
                                    +380 97 004 00 05 (Viber)
                                </a>
                            </Typography>
                            <Typography className={classes.contactText}>
                                <a href="mailto:woodplus15@gmail.com" style={{
                                    textDecoration: 'none', color: 'inherit'
                                }}>
                                    woodplus15@gmail.com
                                </a>
                            </Typography>
                        </Box>
                        <Box className={classes.textContainer}>
                            {/*<Typography className={classes.contactText}>*/}
                            {/*    +380 98 000 00 00*/}
                            {/*</Typography>*/}
                            {/*<CustomButton text="Зателефонувати"/>*/}
                        </Box>
                    </Box>
                    <Box className={classes.buttonContainer}>
                        <ContactButton text="Instagram" to="https://instagram.com/woodplus15?igshid=NGVhN2U2NjQ0Yg=="/>
                        <ContactButton text="Facebook" to="https://www.facebook.com/profile.php?id=100039472862208&locale=pl_PL"/>
                        <ContactButton text="Telegram" to="https://t.me/woodplus15"/>
                        <ContactButton text="Viber" tel="+380970040005"/>
                    </Box>
                </Box>
            </Box>
        );
    }
;

export default Footer;



